<template>
  <div class="productList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>等级列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button
      type="primary"
      class="add"
      @click="dialogVisible = true"
      v-if="$store.state.powerList.indexOf('material:info:list:add') !== -1"
    >
      添加素材
    </el-button>

    <div class="search_box">
      <span>素材名称：</span>
      <el-input
        style="width: 200px"
        v-model="formData.title"
        placeholder="请输入素材名称"
      />
      <el-button type="primary" class="btn" @click="getTableList()"
        >查询</el-button
      >
    </div>
    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <!-- <el-table-column label="排序" width="120">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
			</el-table-column> -->
      <el-table-column prop="materialId" label="素材ID"> </el-table-column>
      <el-table-column prop="title" label="素材标题"> </el-table-column>
      <el-table-column prop="cateName" label="所属分类"> </el-table-column>
      <el-table-column prop="summary" label="素材文案"> </el-table-column>
      <el-table-column prop="addTime" label="添加时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm
            v-if="
              $store.state.powerList.indexOf('material:info:list:delete') !== -1
            "
            title="确认删除该产品吗？"
            @confirm="toModify(0, [scope.row])"
          >
            <el-button type="primary" size="mini" slot="reference">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 0.625rem"
            @click="showEdit(scope.row)"
            v-if="
              $store.state.powerList.indexOf('material:info:list:edit') !== -1
            "
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="formData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="formData.total"
    >
    </el-pagination>

    <div class="btn_box">
      <el-popconfirm
        title="确认删除该产品吗？"
        v-if="
          $store.state.powerList.indexOf('material:info:list:delete') !== -1
        "
        @confirm="toModify(0, multipleSelection)"
      >
        <el-button type="primary" slot="reference">批量删除</el-button>
      </el-popconfirm>
    </div>

    <el-dialog title="添加素材" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="	素材标题">
          <el-input placeholder="请输入素材标题" v-model="addForm.title" />
        </el-form-item>
        <el-form-item label="所属分类">
          <el-select v-model="addForm.cateId" placeholder="请选择卡片分类">
            <el-option
              v-for="item in options"
              :key="item.cateId"
              :label="item.cateName"
              :value="item.cateId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="	素材文案">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入素材文案"
            v-model="addForm.summary"
          />
        </el-form-item>

        <el-form-item label="素材图片">
          <div class="img" v-for="(item, index) in addForm.imgUrl" :key="index">
            <img :src="item" />
            <div class="el-icon-close" @click="onClose"></div>
          </div>
          <div
            class="addImg"
            @click="$refs.fileRefs.click()"
            v-if="addForm.imgUrl.length < 9"
          >
            <input type="file" hidden ref="fileRefs" @change="onFileChange" />
            <i class="el-icon-plus"></i>
          </div>
        </el-form-item>
        <el-form-item label="">
          <span style="color: red"> 建议尺寸375*375px </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideVisible()">取 消</el-button>
        <el-button type="primary" @click="toAdd()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑素材" :visible.sync="dialogVisible2" width="50%">
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="	素材标题">
          <el-input placeholder="请输入素材标题" v-model="editForm.title" />
        </el-form-item>
        <el-form-item label="所属分类">
          <el-select v-model="editForm.cateId" placeholder="请选择卡片分类">
            <el-option
              v-for="item in options"
              :key="item.cateId"
              :label="item.cateName"
              :value="item.cateId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="	素材文案">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入素材文案"
            v-model="editForm.summary"
          />
        </el-form-item>

        <el-form-item label="素材图片">
          <div
            class="img"
            v-for="(item, index) in editForm.imgUrl"
            :key="index"
          >
            <img :src="item" />
            <div class="el-icon-close" @click="onClose2"></div>
          </div>
          <div
            class="addImg"
            @click="$refs.fileRefs2.click()"
            v-if="editForm.imgUrl.length < 9"
          >
            <input type="file" hidden ref="fileRefs2" @change="onFileChange2" />
            <i class="el-icon-plus"></i>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideVisible()">取 消</el-button>
        <el-button type="primary" @click="toEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import productBox from "./productBox";
import {
  cateAllApi,
  materialList,
  materialAdd,
  materialDelete,
  materialDetail,
  changeSort,
} from "../../api/shopMaterial.js";
import { sendFile } from "../../api/sendFile";
export default {
  name: "productList",
  components: {
    productBox,
  },
  data() {
    return {
      options: [],
      formData: {
        title: "",
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      addForm: {
        materialId: 0,
        title: "",
        cateId: "",
        summary: "",
        imgUrl: [],
      },
      editForm: {
        materialId: "",
        title: "",
        cateId: "",
        summary: "",
        imgUrl: [],
      },
      tableData: [],
      multipleSelection: [],
      dialogVisible: false,
      dialogVisible2: false,
    };
  },
  created() {
    this.getCateList(); // 获取分类列表
    this.getTableList(); // 获取表格数据
  },
  methods: {
    async getTableList() {
      const { data } = await materialList(this.formData);
      this.tableData = data.list;
      this.formData.total = data.pagination.total;
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
    },
    async getCateList() {
      const { data } = await cateAllApi();
      this.options = data.data;
    },
    async toAdd() {
      let setData = { ...this.addForm };
      for (var k in setData) {
        if (setData[k] === "" || setData[k].length == 0) {
          console.log(k, setData[k]);
          return this.$message.error("请填写完整的信息");
          break;
        }
        if (k == "imgUrl" && setData[k].length < 1) {
          return this.$message.error("请填写完整的信息");
          break;
        }
      }
      setData.imgUrl = setData.imgUrl.join(",");
      const { data } = await materialAdd(setData);
      if (data.code == 0) {
        this.dialogVisible = false;
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    async toModify(status, list) {
      if (list.length < 1) {
        return this.$message.error("请选择需要操作的商品！");
      }
      let ids = list
        .map((item) => {
          return item.materialId;
        })
        .join(",");
      const { data } = await materialDelete({ ids });
      if (data.code == 0) {
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    async showEdit(row) {
      // this.editForm.materialId = row.materialId
      // this.editForm.title = row.title
      const { data } = await materialDetail({
        materialId: row.materialId,
      });
      let newData = data.data[0];
      this.editForm = newData;
      console.log(this.editForm);
      this.dialogVisible2 = true;
    },
    async toEdit() {
      let setData = { ...this.editForm };
      for (var k in setData) {
        if (setData[k] === "") {
          return this.$message.error("请填写完整的信息");
          break;
        }
        if (k == "imgUrl" && setData[k].length < 1) {
          return this.$message.error("请填写完整的信息");
          break;
        }
      }
      setData.imgUrl = setData.imgUrl.join(",");
      const { data } = await materialAdd(setData);
      console.log("编辑回调", data);
      if (data.code == 0) {
        this.dialogVisible2 = false;
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    // 开始修改排序
    onChangeSort(row) {
      row.inputVisible = true;
      this.tableData = [...this.tableData];
    },
    // 修改排序
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        materialId: row.materialId,
        dept: Number(row.dept),
      });
      if (data.code !== 0) {
        this.$message({
          message: "设置失败",
          type: "error",
        });
      } else {
        this.$message({
          message: "设置成功",
          type: "success",
        });
      }
      row.inputVisible = false;
    },
    onClose(index) {
      this.addForm.imgUrl.splice(index, 1);
    },
    onClose2(index) {
      this.editForm.imgUrl.splice(index, 1);
    },
    async onFileChange(event) {
      // 根据这个 <input> 获取文件的 HTML5 js 对象
      var files = event.target.files;
      if (files && files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        this.addForm.imgUrl.push(data.data[0].src);
        this.addForm = { ...this.addForm };
        console.log("上传文件", this.addForm);
      }
    },
    async onFileChange2(event) {
      // 根据这个 <input> 获取文件的 HTML5 js 对象
      var files = event.target.files;
      if (files && files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        this.editForm.imgUrl.push(data.data[0].src);
        this.editForm = { ...this.editForm };
        console.log("上传文件", this.editForm);
      }
    },
    // 隐藏弹窗
    hideVisible() {
      this.dialogVisible = false;
      this.dialogVisible2 = false;
    },
    handleSelectionChange(arr) {
      this.multipleSelection = arr;
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getTableList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getTableList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.productList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin-left: 20px;
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }

  /deep/ .el-form {
    width: 800px;
    margin-top: 50px;
    .col,
    .col1 {
      display: inline-block;
      .el-form-item__content {
        width: 200px;
      }
    }

    .el-form-item__content {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
      .richEdit {
        height: 500px;
        .ql-container {
          height: 350px;
        }
      }
      .img {
        position: relative;
        height: 100px;
        width: 100px;
        margin: 10px 20px 20px;
        img {
          height: 100px;
          width: 100px;
          border: 1px solid #c0ccda;
        }
        div {
          position: absolute;
          top: 0;
          right: 2px;
          height: 10px;
          width: 10px;
          z-index: 100;
          cursor: pointer;
        }
      }
      .addImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px dashed #c0ccda;
        text-align: center;
        line-height: 100px;
        cursor: pointer;
        margin-top: 10px;
        i {
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #c0ccda;
        }
      }
    }
  }
}
</style>